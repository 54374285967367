import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ModalDialog, useModalActions } from '../../../../shared/component/modal-dialog'
import { useApiFormAuthDevice, useApiFormAuthPassiveLogin } from '../../../../api-new/state/auth'
import { IconLoginDevice } from '../../../../shared/icon/icon'
import { useAuthContext } from '../../../../context/auth.context'
import { useLoginRedirect } from './hooks'

function useStateRef(initialValue) {
  const [value, setValue] = useState(initialValue)

  const ref = useRef(value)

  useEffect(
    () => {
      ref.current = value
    },
    [value]
  )

  return [value, setValue, ref]
}

const useInterval = (timout, callback) => {
  const [, updateState, refInterval] = useStateRef(null)

  const start = () => {
    if (!refInterval.current) {
      const interval = setInterval(() => {
        callback()
      }, timout)

      updateState(interval)
    }
  }

  const stop = () => {
    if (refInterval.current) {
      clearInterval(refInterval.current)
      updateState(null)
    }
  }

  return [start, stop]
}

export const LoginDeviceModal = ({ target }) => {
  const authContext = useAuthContext()

  const loginRedirect = useLoginRedirect()

  const apiFormAuthDevice = useApiFormAuthDevice()
  const apiFormAuthPassiveLogin = useApiFormAuthPassiveLogin()

  const modalActions = useModalActions(target)

  const [authDevice, updateAuthDevice, refAuthDevice] = useStateRef(null)

  const callAuthDevice = async () => {
    const clientInfo = getOsType(navigator.userAgent)
    let authDevice = await apiFormAuthDevice.post({ clientInfo: clientInfo })
    updateAuthDevice(authDevice)
  }

  const [start, stop] = useInterval(2000, async () => {
    try {
      let passiveLogin = await apiFormAuthDevice.get(`${refAuthDevice.current.code}`)

      if (passiveLogin) {
        stop()

        let authToken = await apiFormAuthPassiveLogin.get(passiveLogin.token)
        authContext.actions.setAuthToken(authToken)

        modalActions.hide()

        loginRedirect('/')
      }
    } catch (exc) {
      if (exc.status === 404) {
        await callAuthDevice()
        return
      }

      if (exc.status !== 403) {
        stop()
        return
      }
    }
  })

  const handleShow = async () => {
    if (!refAuthDevice.current) {
      await callAuthDevice()
      start()
    }
  }

  const handleHide = () => {}

  useEffect(() => {
    return () => stop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let code = useMemo(() => (authDevice ? authDevice.code.split('') : []), [authDevice])

  return (
    <React.Fragment>
      <ModalDialog target={target} Icon={IconLoginDevice} header="Login another device" onShow={handleShow} onHide={handleHide}>
        <React.Fragment>
          <ul>
            <ListItem>Open EducationXR on a device you're already logged in</ListItem>
            <ListItem>
              Click the <IconLoginDevice /> button
            </ListItem>
            <ListItem>Enter the code below</ListItem>
          </ul>

          <div className="d-flex justify-content-center">
            {code.map((c, i) => <CodeItem key={i} value={c} />)}
          </div>
        </React.Fragment>
      </ModalDialog>
    </React.Fragment>
  )
}

const ListItem = ({ children }) => {
  return (
    <li className="m-1">
      {children}
    </li>
  )
}

const CodeItem = ({ value }) => {
  return (
    <div className="p-2 m-1 border rounded bg-dark" style={{ minWidth: 45 }}>
      <div className="d-flex justify-content-center fs-1 text-light">
        {value}
      </div>
    </div>
  )
}

const getOsType = userAgent => {
  if (/Macintosh/.test(userAgent)) {
    return 'Macintosh'
  }

  if (/Windows/.test(userAgent)) {
    return 'Windows'
  }

  if (/Linux/.test(userAgent)) {
    return 'Linux'
  }

  if (/iPhone/.test(userAgent)) {
    return 'iPhone'
  }

  if (/iPad/.test(userAgent)) {
    return 'iPad'
  }

  if (/Android/.test(userAgent)) {
    return 'Android'
  }

  return 'Admin Website'
}
