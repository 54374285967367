import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconAdd, IconAiDocument } from '../../../../../../shared/icon/icon'
import { useApiFormEmbedding, useApiListDocument } from '../../../../../../api-new/state/ai'
import { Card } from '../../../../../../shared/card/card'
import { FileUpload } from '../../../../../../shared/legacy/storage/file-upload'
import { SizeDisplay } from '../../../../../../shared/legacy/size-display'
import { FormatDate } from '../../../../../../shared/legacy/format'
import { Menu, MenuDelete, MenuItem } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const AiDocumentList = ({ nodeID }) => {
  const apiListDocument = useApiListDocument()
  const apiFormEmbedding = useApiFormEmbedding()

  const handleMatch = () => apiListDocument.get(`${nodeID}/node`)

  const handleAddDocument = async () => apiListDocument.post({ nodeID: nodeID, name: 'document' })

  const handleUploadComplete = async (asset, file) => {
    asset.name = file.name
    asset.length = file.size
    apiListDocument.put(asset.id, asset)
  }

  const handleDelete = asset => {
    apiListDocument.delete(asset.id)
  }

  const handleScan = asset => {
    apiFormEmbedding.post({ assetID: asset.id })
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconAdd} text="add" onClick={handleAddDocument} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAiDocument} text="ai documents" onMatch={handleMatch}>
        <CardFullHeightScrollY title="AI Documents" HeaderMenu={HeaderMenu}>
          <div>
            {apiListDocument.items.map((asset, i) =>
              <AiDocument key={i} asset={asset} onUploadComplete={handleUploadComplete} onScan={handleScan} onDelete={handleDelete} />
            )}
          </div>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const AiDocument = ({ asset, onUploadComplete, onScan, onDelete }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showFile = useMemo(() => asset && asset.length !== 0, [asset, asset.length])

  const handleComplete = file => onUploadComplete && onUploadComplete(asset, file)
  const handleScan = () => onScan && onScan(asset)
  const handleDelete = () => onDelete && onDelete(asset)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconAiDocument} text="Scan" show onClick={handleScan} />
      <MenuDelete show onClick={handleDelete} />
    </Menu>

  return (
    <Card title="AI Document" HeaderMenu={HeaderMenu}>
      {showFile &&
        <div className="p-2">
          <div>
            Filename: {asset.name}
          </div>
          <div>
            Filesize: <SizeDisplay size={asset.length} />
          </div>
          <div>
            Uploaded On: <FormatDate date={asset.updatedAt} />
          </div>
        </div>}
      <div className="pt-3">
        <FileUpload storageKey={asset.key} onComplete={handleComplete} />
      </div>
    </Card>
  )
}
